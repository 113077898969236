(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("chevrotain"), require("lit-html"), require("core-js/modules/web.dom-collections.iterator"), require("@finos/perspective/dist/esm/config"), require("@finos/perspective/dist/esm/config/constants.js"), require("core-js/modules/es.string.replace"), require("lodash/debounce"), require("awesomplete"), require("lodash/isEqual"), require("core-js/modules/es.typed-array.uint8-array"), require("core-js/modules/web.url"), require("@webcomponents/webcomponentsjs"), require("core-js/modules/es.array.unscopables.flat-map"));
	else if(typeof define === 'function' && define.amd)
		define(["chevrotain", "lit-html", "core-js/modules/web.dom-collections.iterator", "@finos/perspective/dist/esm/config", "@finos/perspective/dist/esm/config/constants.js", "core-js/modules/es.string.replace", "lodash/debounce", "awesomplete", "lodash/isEqual", "core-js/modules/es.typed-array.uint8-array", "core-js/modules/web.url", "@webcomponents/webcomponentsjs", "core-js/modules/es.array.unscopables.flat-map"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("chevrotain"), require("lit-html"), require("core-js/modules/web.dom-collections.iterator"), require("@finos/perspective/dist/esm/config"), require("@finos/perspective/dist/esm/config/constants.js"), require("core-js/modules/es.string.replace"), require("lodash/debounce"), require("awesomplete"), require("lodash/isEqual"), require("core-js/modules/es.typed-array.uint8-array"), require("core-js/modules/web.url"), require("@webcomponents/webcomponentsjs"), require("core-js/modules/es.array.unscopables.flat-map")) : factory(root["chevrotain"], root["lit-html"], root["core-js/modules/web.dom-collections.iterator"], root["@finos/perspective/dist/esm/config"], root["@finos/perspective/dist/esm/config/constants.js"], root["core-js/modules/es.string.replace"], root["lodash/debounce"], root["awesomplete"], root["lodash/isEqual"], root["core-js/modules/es.typed-array.uint8-array"], root["core-js/modules/web.url"], root["@webcomponents/webcomponentsjs"], root["core-js/modules/es.array.unscopables.flat-map"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__3__, __WEBPACK_EXTERNAL_MODULE__4__, __WEBPACK_EXTERNAL_MODULE__6__, __WEBPACK_EXTERNAL_MODULE__8__, __WEBPACK_EXTERNAL_MODULE__9__, __WEBPACK_EXTERNAL_MODULE__10__, __WEBPACK_EXTERNAL_MODULE__24__, __WEBPACK_EXTERNAL_MODULE__25__, __WEBPACK_EXTERNAL_MODULE__26__, __WEBPACK_EXTERNAL_MODULE__27__, __WEBPACK_EXTERNAL_MODULE__29__) {
return 